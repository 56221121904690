
import MenuCategories from '@/graphql/queries/taxonomies/MenuCategories'

export default {
  vessel: {
    search: {
      padding: 'sm',
      borderBottomWidth: 1,
      backgroundColor: 'grey-lighter'
    }
  },
  async fetch() {
    const { data } = await this.$graphql.query({
      clientId: 'eshop',
      query: MenuCategories
    })

    this.categories = data.categories.map(taxon => ({
      label: taxon.name,
      route: this.$pagePath({ name: 'categories-id', params: { id: taxon.slug } }),
      translate: false
    }))
  },
  data() {
    return {
      categories: []
    }
  },
  computed: {
    pages() {
      return [
        { label: 'pages.home', route: '/' },
        { label: 'pages.products', route: '/categories' },
        { label: 'pages.contact', route: '/contact' }
      ]
    },
    links() {
      return [
        ...this.pages,
        {
          title: this.$t('categories'),
          content: this.categories
        }
      ]
    }
  }
}
