import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=29eca82c"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/build/repo/components/Logo.vue').default,MenuActions: require('/opt/build/repo/components/layout/MenuActions.vue').default})
