import 'cross-fetch/polyfill'

const useTimeoutAt = (timeFn, callback) => {
  let isActive  = false
  let timeoutId = null

  const stop = () => {
    isActive = false
    clearTimeout(timeoutId)
  }

  const exec = async () => {
    isActive = true
    await callback()
    isActive = false
  }

  const start = () => {
    stop()

    const val = Math.floor(timeFn() - Date.now())
    timeoutId = setTimeout(exec, val)
  }

  return { start, stop, isActive }
}

class Service {
  constructor(app) {
    this.endpoints = {"login":{"url":"https:\u002F\u002Fdata.statica.eu\u002Foauth\u002Ftoken","method":"post","propertyName":false},"logout":{"url":"https:\u002F\u002Fdata.statica.eu\u002Foauth\u002Frevoke","method":"post"},"user":{"url":"https:\u002F\u002Fdata.statica.eu\u002Foauth\u002Ftoken\u002Finfo","method":"get","propertyName":false}}
    this.getLocale = () => app.i18n.locale
  }

  async request(endpoint, { payload, headers } = {}) {
    const { url, method, propertyName } = this.endpoints[endpoint]

    const options = {
      method: method.toUpperCase(),
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
        'Tenant': 'lpg-shop',
        'Locale': this.getLocale(),
        ...headers
      }
    }

    if (method == 'post' && payload) {
      options.body = JSON.stringify(payload)
    }

    const resp = await fetch(url, options)
    const data = await resp.json()

    return propertyName ? data[propertyName] : data
  }

  async login(payload) {
    return await this.request('login', { payload })
  }

  async logout(payload) {
    return await this.request('logout', { payload })
  }
}

export default (ctx, inject) => {
  const { app, store, route } = ctx

  const service = new Service(app)
  const redirects = {"login":"\u002Fauth\u002Flogin","logout":"\u002F","home":"\u002F"}

  const autoLogout = useTimeoutAt(
    () => auth.expiresAt,
    () => auth.autoLogout()
  )

  const autoRefresh = useTimeoutAt(
    () => auth.expiresAt - 10000,
    () => auth.refresh()
  )

  const afterLogin = async () => {
    await app.$graphql.clearStore()
    await app.$user.fetch()
  }

  const afterLogout = async () => {
    await app.$user.reset()
    await app.$graphql.clearStore()
  }

  const auth = {
    canRegister: true,
    get loggedIn() {
      return this.token != null && this.expiresAt > Date.now()
    },
    get token() {
      return store.getters['auth/token']
    },
    get refreshToken() {
      return store.getters['auth/refreshToken']
    },
    get expiresAt() {
      return store.getters['auth/expiresAt'] || Date.now()
    },
    getToken() {
      return this.loggedIn ? `Bearer ${this.token}` : null
    },
    setToken(value) {
      store.commit('auth/SET_TOKEN', value)

      autoRefresh.start()
      autoLogout.start()
    },
    setLastPath(path) {
      store.commit('auth/SET_LAST', path)
    },
    setRedirect(path) {
      store.commit('auth/SET_REDIRECT', path)
    },
    reset() {
      store.commit('auth/RESET')

      autoRefresh.stop()
      autoLogout.stop()
    },
    redirect(name) {
      let path = redirects['home']

      if (name) {
        path = redirects[name] || path
      } else {
        path = store.getters['auth/redirect'] || path
        this.setRedirect(null)
      }

      if (path != app.router.currentRoute.path) {
        ctx.redirect(path)
      }
    },
    async login({ data, onSuccess, onError }) {
      try {
        const resp = await service.login(data)
        const path = app.router.currentRoute.path

        if (resp.error) {
          onError && await onError(resp)
        } else {
          this.setToken(resp)

          await afterLogin()
          onSuccess && await onSuccess(resp)

          if (path.match('/auth/')) {
            this.redirect()
          }
        }
      } catch (e) {
        console.log(e)
        onError && await onError()
      }
    },
    async logout({ onSuccess, onError }) {
      try {
        const resp = await service.logout({
          token: this.token
        })

        if (resp.error) {
          onError && await onError(resp)
        } else {
          this.reset()
          onSuccess && await onSuccess(resp)

          setTimeout(afterLogout, 1000)
          this.redirect('logout')
        }
      } catch (e) {
        console.log(e)
        onError && await onError()
      }
    },
    async refresh() {
      try {
        const resp = await service.login({
          grant_type: 'refresh_token',
          refresh_token: this.refreshToken
        })

        this.setToken(resp)
      } catch (e) {
        console.log(e)
      }
    },
    async autoLogin() {
      if (!this.loggedIn && this.refreshToken) {
        await this.refresh()
        await afterLogin()
      }
    },
    async autoLogout() {
      const last = store.getters['auth/lastPath']
      const path = app.router.currentRoute.path

      if (this.expiresAt < Date.now()) {
        this.reset()
        setTimeout(afterLogout, 1000)
      }

      if (!this.loggedIn && path == last) {
        store.commit('auth/RESET_LAST')
        this.redirect('login')
      }
    }
  }

  if (auth.loggedIn) {
    autoRefresh.start()
    autoLogout.start()
  } else {
    autoRefresh.stop()
    autoLogout.stop()
  }

  inject('auth', auth)
}
