import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=0db9d5d2"
import script from "./Layout.vue?vue&type=script&lang=js"
export * from "./Layout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoBar: require('/opt/build/repo/components/layout/InfoBar.vue').default,TopBar: require('/opt/build/repo/components/layout/TopBar.vue').default,MegaMenu: require('/opt/build/repo/components/layout/MegaMenu.vue').default,FeaturesSection: require('/opt/build/repo/components/sections/FeaturesSection.vue').default,FooterColumns: require('/opt/build/repo/components/layout/FooterColumns.vue').default,Copyright: require('/opt/build/repo/components/layout/Copyright.vue').default,EshopCartDrawer: require('/opt/build/repo/node_modules/@evince/eshop/lib/packages/CartDrawer.vue').default,ScCookieBar: require('/opt/build/repo/node_modules/@evince/site/lib/packages/CookieBar.vue').default})
