
export default {
  props: {
    headerImage: {
      type: Boolean,
      default: false
    }
  },
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    footer: {
      borderTopWidth: 1,
      overflow: 'hidden'
    }
  }
}
