

export default {
  vessel: {
    copyright: {
      fontSize: 'sm',
      marginBottom: { all: 'sm', xl: 0 },
    }
  },
  computed: {
    copyright() {
      const title = this.$t('footer.copyrightTitle')
      const rights = this.$t('footer.rightsReserved')

      return `${title}. ${rights}`
    }
  }
}
