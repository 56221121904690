export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"info@brc.gr","address":"Υψούντος 13, Περιστέρι 121 32","geoAddress":"BRC Greece","phone":"+30 210 4611212","businessHours":[{"days":"Δευτέρα - Παρασκευή","hours":"09:00 - 17:00"}]},"en":{"icons":true,"email":"info@brc.gr","address":"Ipsountos 13, Peristeri 121 32","geoAddress":"BRC Greece","phone":"+30 210 4611212","businessHours":[{"days":"Monday - Friday","hours":"09:00 - 17:00"}]}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
