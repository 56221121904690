
import MenuCategories from '@/graphql/queries/taxonomies/MenuCategories'

export default {
  vessel: {
    container: {
      position: 'relative'
    },
    menu: {
      position: 'absolute',
      borderTopWidth: 1,
      width: 'full',
      pinLeft: true,
      shadow: true
    },
    link: {
      hover: {
        color: 'primary'
      }
    },
    activeLink: {
      color: 'primary'
    },
    category: {
      $extend: 'link',
      fontWeight: 'normal',
      fontSize: 'sm',
      cursor: 'pointer',
      font: 'title'
    },
    activeCategory: {
      $extend: 'category',
      color: 'primary'
    }
  },
  watch: {
    '$route.path': {
      handler: 'close'
    }
  },
  async fetch() {
    const { data } = await this.$graphql.query({
      clientId: 'eshop',
      query: MenuCategories
    })

    this.categories = data.categories
  },
  data() {
    return {
      categories: null,
      active: null,
    }
  },
  computed: {
    activeName() {
      return this.active && this.active.name
    },
    activeSlug() {
      return this.$route.params.id
    },
    itemImage() {
      return {
        ratio: 'square',
        fit: 'cover',
        borderWidth: 1,
        width: 'xl',
        height: 'xl',
        ...this.active.image
      }
    },
  },
  methods: {
    hasChildren(category) {
      return category.children.length
    },
    itemLink({ slug }) {
      const path = { name: 'categories-id', params: { id: slug } }
      return this.$pagePath(path)
    },
    toggle(category) {
      if (!this.hasChildren(category)) {
        return
      }

      if (this.activeName == category.name) {
        this.close()
      } else {
        this.active = category
      }
    },
    close() {
      this.active = null
    },
    isActive(category) {
      const active = ({ name, slug }) => {
        return this.activeName == name || this.activeSlug == slug
      }

      const activeChild = (cat) => {
        const items = cat.children || []
        return items.some(child => active(child) || activeChild(child))
      }

      return active(category) || activeChild(category)
    },
    categoryClass(category) {
      return this.isActive(category)
        ? this.$vessels.activeCategory
        : this.$vessels.category
    },
    linkClass(category) {
      return this.isActive(category)
        ? this.$vessels.activeLink
        : this.$vessels.link
    }
  }
}
