import { render, staticRenderFns } from "./InfoBar.vue?vue&type=template&id=9bc2c660"
import script from "./InfoBar.vue?vue&type=script&lang=js"
export * from "./InfoBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ScContactDetails: require('/opt/build/repo/node_modules/@evince/site/lib/packages/contact/Details.vue').default})
