
import FooterTaxons from '@/graphql/queries/taxonomies/FooterTaxons'

export default {
  vessel: {
    section: {
      position: 'relative',
      backgroundColor: 'grey-lightest'
    },
    social: {
      marginTop: 'md',
      color: 'grey-dark',
      negativeMarginLeft: 'xs'
    }
  },
  async fetch() {
    const { data } = await this.$graphql.query({
      clientId: 'eshop',
      query: FooterTaxons
    })

    this.categories = data.categories.map((taxon) => ({
      label: taxon.name,
      path: { name: 'categories-id', params: { id: taxon.slug } },
      translate: false
    }))
  },
  data() {
    return {
      categories: null,
      quickLinks: [
        {
          path: '/products',
          label: 'pages.products',
          translate: true
        },
        {
          path: '/shopping-cart',
          label: 'eshop.shoppingCart',
          translate: true
        },
        {
          path: '/contact',
          label: 'pages.contact',
          translate: true
        },
        {
          path: '/cookie-policy',
          label: 'site.cookiePolicy',
          translate: true
        },
        {
          path: '/terms-and-conditions',
          label: 'pages.terms',
          translate: true
        }
      ],
      socialAccounts: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/Brc.Greece/'
        },
      ]
    }
  }
}
