export const AuthSession = () => import('../../node_modules/@evince/auth/lib/packages/AuthSession.vue' /* webpackChunkName: "components/auth-session" */).then(c => wrapFunctional(c.default || c))
export const AuthSessionForm = () => import('../../node_modules/@evince/auth/lib/packages/AuthSessionForm.vue' /* webpackChunkName: "components/auth-session-form" */).then(c => wrapFunctional(c.default || c))
export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const EshopCart = () => import('../../node_modules/@evince/eshop/lib/packages/Cart.vue' /* webpackChunkName: "components/eshop-cart" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAdd = () => import('../../node_modules/@evince/eshop/lib/packages/CartAdd.vue' /* webpackChunkName: "components/eshop-cart-add" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddButton = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddButton.vue' /* webpackChunkName: "components/eshop-cart-add-button" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddForm.vue' /* webpackChunkName: "components/eshop-cart-add-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartDrawer = () => import('../../node_modules/@evince/eshop/lib/packages/CartDrawer.vue' /* webpackChunkName: "components/eshop-cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const EshopCartForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartForm.vue' /* webpackChunkName: "components/eshop-cart-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartRemove = () => import('../../node_modules/@evince/eshop/lib/packages/CartRemove.vue' /* webpackChunkName: "components/eshop-cart-remove" */).then(c => wrapFunctional(c.default || c))
export const EshopCartToggle = () => import('../../node_modules/@evince/eshop/lib/packages/CartToggle.vue' /* webpackChunkName: "components/eshop-cart-toggle" */).then(c => wrapFunctional(c.default || c))
export const EshopSearchInput = () => import('../../node_modules/@evince/eshop/lib/packages/SearchInput.vue' /* webpackChunkName: "components/eshop-search-input" */).then(c => wrapFunctional(c.default || c))
export const PageEshopProducts = () => import('../../node_modules/@evince/eshop/lib/templates/Products.vue' /* webpackChunkName: "components/page-eshop-products" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const UserSession = () => import('../../components/UserSession.vue' /* webpackChunkName: "components/user-session" */).then(c => wrapFunctional(c.default || c))
export const BrandsList = () => import('../../components/categories/BrandsList.vue' /* webpackChunkName: "components/brands-list" */).then(c => wrapFunctional(c.default || c))
export const CategoriesList = () => import('../../components/categories/CategoriesList.vue' /* webpackChunkName: "components/categories-list" */).then(c => wrapFunctional(c.default || c))
export const CategoryCardVertical = () => import('../../components/categories/CategoryCardVertical.vue' /* webpackChunkName: "components/category-card-vertical" */).then(c => wrapFunctional(c.default || c))
export const BaseSection = () => import('../../components/layout/BaseSection.vue' /* webpackChunkName: "components/base-section" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/layout/FooterColumn.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/layout/FooterColumns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../components/layout/HomeHeader.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const ImageHeader = () => import('../../components/layout/ImageHeader.vue' /* webpackChunkName: "components/image-header" */).then(c => wrapFunctional(c.default || c))
export const InfoBar = () => import('../../components/layout/InfoBar.vue' /* webpackChunkName: "components/info-bar" */).then(c => wrapFunctional(c.default || c))
export const Layout = () => import('../../components/layout/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const MegaMenu = () => import('../../components/layout/MegaMenu.vue' /* webpackChunkName: "components/mega-menu" */).then(c => wrapFunctional(c.default || c))
export const MenuActions = () => import('../../components/layout/MenuActions.vue' /* webpackChunkName: "components/menu-actions" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const PromotionCard = () => import('../../components/promotions/PromotionCard.vue' /* webpackChunkName: "components/promotion-card" */).then(c => wrapFunctional(c.default || c))
export const PromotionExtendedCard = () => import('../../components/promotions/PromotionExtendedCard.vue' /* webpackChunkName: "components/promotion-extended-card" */).then(c => wrapFunctional(c.default || c))
export const PromotionProducts = () => import('../../components/promotions/PromotionProducts.vue' /* webpackChunkName: "components/promotion-products" */).then(c => wrapFunctional(c.default || c))
export const PromotionsCarousel = () => import('../../components/promotions/PromotionsCarousel.vue' /* webpackChunkName: "components/promotions-carousel" */).then(c => wrapFunctional(c.default || c))
export const PromotionsList = () => import('../../components/promotions/PromotionsList.vue' /* webpackChunkName: "components/promotions-list" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributes = () => import('../../components/products/ProductAttributes.vue' /* webpackChunkName: "components/product-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/products/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardLoading = () => import('../../components/products/ProductCardLoading.vue' /* webpackChunkName: "components/product-card-loading" */).then(c => wrapFunctional(c.default || c))
export const ProductCountry = () => import('../../components/products/ProductCountry.vue' /* webpackChunkName: "components/product-country" */).then(c => wrapFunctional(c.default || c))
export const ProductDesktop = () => import('../../components/products/ProductDesktop.vue' /* webpackChunkName: "components/product-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/products/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductForm = () => import('../../components/products/ProductForm.vue' /* webpackChunkName: "components/product-form" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/products/ProductGallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductGalleryCarousel = () => import('../../components/products/ProductGalleryCarousel.vue' /* webpackChunkName: "components/product-gallery-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductMeasurements = () => import('../../components/products/ProductMeasurements.vue' /* webpackChunkName: "components/product-measurements" */).then(c => wrapFunctional(c.default || c))
export const ProductMobile = () => import('../../components/products/ProductMobile.vue' /* webpackChunkName: "components/product-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/products/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceMinimal = () => import('../../components/products/ProductPriceMinimal.vue' /* webpackChunkName: "components/product-price-minimal" */).then(c => wrapFunctional(c.default || c))
export const ProductTaxonLink = () => import('../../components/products/ProductTaxonLink.vue' /* webpackChunkName: "components/product-taxon-link" */).then(c => wrapFunctional(c.default || c))
export const ProductsSearch = () => import('../../components/products/ProductsSearch.vue' /* webpackChunkName: "components/products-search" */).then(c => wrapFunctional(c.default || c))
export const BrandsSection = () => import('../../components/sections/BrandsSection.vue' /* webpackChunkName: "components/brands-section" */).then(c => wrapFunctional(c.default || c))
export const CategoriesSection = () => import('../../components/sections/CategoriesSection.vue' /* webpackChunkName: "components/categories-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturesSection = () => import('../../components/sections/FeaturesSection.vue' /* webpackChunkName: "components/features-section" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/sections/NewsletterSubscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const NewslettersOffers = () => import('../../components/sections/NewslettersOffers.vue' /* webpackChunkName: "components/newsletters-offers" */).then(c => wrapFunctional(c.default || c))
export const PromotionsSection = () => import('../../components/sections/PromotionsSection.vue' /* webpackChunkName: "components/promotions-section" */).then(c => wrapFunctional(c.default || c))
export const RelatedProducts = () => import('../../components/sections/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c))
export const SuggestedProducts = () => import('../../components/sections/SuggestedProducts.vue' /* webpackChunkName: "components/suggested-products" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
